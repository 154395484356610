const Logo = () => {
  return (
    <svg
      width="200px"
      height="32px"
      viewBox="0 0 1521 213"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1971_28294)">
        <path
          d="M165.772 111.705C162.927 111.705 160.2 112.835 158.188 114.846C156.177 116.858 155.047 119.586 155.047 122.43V148.443C155.047 151.287 156.177 154.015 158.188 156.026C160.2 158.038 162.927 159.167 165.772 159.167C168.616 159.167 171.344 158.038 173.355 156.026C175.366 154.015 176.496 151.287 176.496 148.443V122.43C176.496 119.586 175.366 116.858 173.355 114.846C171.344 112.835 168.616 111.705 165.772 111.705V111.705Z"
          fill="black"
        />
        <path
          d="M135.88 183.322C129.657 183.322 124.582 178.79 124.582 173.246H128.237C128.237 176.796 131.666 179.681 135.88 179.681C140.094 179.681 143.538 176.796 143.538 173.246H147.193C147.193 178.805 142.118 183.322 135.88 183.322Z"
          fill="black"
          stroke="black"
          stroke-width="0.755291"
          stroke-miterlimit="10"
        />
        <path
          d="M105.701 111.705C102.857 111.705 100.129 112.835 98.1182 114.846C96.107 116.858 94.9771 119.586 94.9771 122.43V148.443C94.9771 151.287 96.107 154.015 98.1182 156.026C100.129 158.038 102.857 159.167 105.701 159.167C108.546 159.167 111.273 158.038 113.284 156.026C115.296 154.015 116.426 151.287 116.426 148.443V122.43C116.426 119.586 115.296 116.858 113.284 114.846C111.273 112.835 108.546 111.705 105.701 111.705Z"
          fill="black"
        />
        <ellipse
          cx="133.028"
          cy="125.055"
          rx="96.957"
          ry="85.212"
          fill="#1B1B1B"
        />
        <path
          d="M266.296 101.89C264.111 78.7885 256.637 7.85806 256.532 5.22096C256.411 2.13178 252.012 -1.80145 252.012 3.90976C252.012 7.85788 248.456 53.201 248.456 86.0065C248.079 85.449 247.853 85.1475 247.853 85.1475H239.716C225.1 49.2075 186.27 23.1229 140.133 21.5406H139.093H138.068C137.285 21.5406 136.561 21.5406 135.688 21.5406C134.874 21.5406 134.09 21.5406 133.307 21.5406H132.282H131.273C85.1198 23.0475 46.2749 49.2075 31.659 85.1475H24.1251L23.5374 86.0065C23.5374 53.201 19.9664 7.85788 19.9664 3.90976C19.9664 -1.80145 15.5665 2.13178 15.446 5.22096C15.3556 7.85806 7.91211 78.7885 5.69713 101.89C2.48968 106.17 0.774689 111.384 0.815153 116.733C0.815153 125.277 6.46558 134.379 13.4119 134.379C15.1778 139.313 17.5841 143.995 20.5691 148.303H31.7343C46.8022 185.403 87.6964 212 135.703 212C183.709 212 224.603 185.403 239.671 148.303H251.379C254.357 143.991 256.763 139.31 258.536 134.379C265.482 134.379 271.118 125.337 271.118 116.733C271.172 111.392 269.479 106.179 266.296 101.89V101.89ZM135.688 205.565C85.843 205.565 45.4462 173.106 45.4462 133.052C45.4462 103.095 68.0478 77.3869 100.278 66.3261L106.17 80.9133C106.677 82.1645 107.545 83.2357 108.665 83.9901C109.784 84.7445 111.103 85.1473 112.453 85.1475H159.073C160.423 85.1473 161.742 84.7445 162.861 83.9901C163.981 83.2357 164.849 82.1645 165.356 80.9133L171.233 66.3865C203.388 77.4774 225.914 103.14 225.914 133.052C225.914 173.106 185.517 205.565 135.688 205.565Z"
          fill="#F6C349"
        />
        <path
          d="M158.431 115.186C160.343 113.273 162.937 112.199 165.642 112.199C168.347 112.199 170.941 113.273 172.853 115.186C174.766 117.099 175.84 119.693 175.84 122.398V148.347C175.84 151.052 174.766 153.646 172.853 155.559C170.941 157.472 168.347 158.546 165.642 158.546C162.937 158.546 160.343 157.472 158.431 155.559C156.518 153.646 155.444 151.052 155.444 148.347V122.398C155.444 119.693 156.518 117.099 158.431 115.186Z"
          fill="white"
          stroke="white"
        />
        <path
          d="M135.818 183.142C129.61 183.142 124.547 178.621 124.547 173.091H128.193C128.193 176.632 131.614 179.511 135.818 179.511C140.022 179.511 143.457 176.632 143.457 173.091H147.103C147.103 178.637 142.041 183.142 135.818 183.142Z"
          fill="white"
          stroke="white"
          stroke-width="0.5"
          stroke-miterlimit="10"
        />
        <path
          d="M98.5042 115.186C100.417 113.273 103.011 112.199 105.715 112.199C108.42 112.199 111.014 113.273 112.927 115.186C114.839 117.099 115.914 119.693 115.914 122.398V148.347C115.914 151.052 114.839 153.646 112.927 155.559C111.014 157.472 108.42 158.546 105.715 158.546C103.011 158.546 100.417 157.472 98.5042 155.559C96.5916 153.646 95.5171 151.052 95.5171 148.347V122.398C95.5171 119.693 96.5916 117.099 98.5042 115.186Z"
          fill="white"
          stroke="white"
        />
      </g>
      <path
        d="M329.972 116.006C329.972 69.1365 359.581 35.7681 407.774 35.7681C448.003 35.7681 475.652 59.1951 482.274 98.7637H455.123C450.266 74.4463 432.361 59.6326 407.82 59.6326C376.656 59.6326 357.198 81.5215 357.198 116.006C357.198 151.381 376.656 173.044 407.82 173.044C432.799 173.044 450.266 158.667 455.123 134.139H482.274C475.2 174.371 448.23 197.134 407.774 197.134C360.245 197.104 329.972 164.4 329.972 116.006Z"
        fill="#1B1B1B"
      />
      <path d="M630.367 193.801V28H656.447V193.801H630.367Z" fill="#1B1B1B" />
      <path d="M679.453 193.801V28H705.533V193.801H679.453Z" fill="#1B1B1B" />
      <path
        d="M722.38 161.262C722.606 133.852 749.787 128.074 772.548 125.45C790.242 123.458 803.274 122.795 803.501 113.518C803.274 102.023 794.45 94.5101 779.366 94.5101C763.89 94.5101 752.834 102.897 751.295 115.071H724.989C727.418 90.3012 749.515 73.0586 779.804 73.0586C811.48 73.0586 828.872 90.301 828.872 115.297V168.353C828.872 171.671 830.652 173.436 834.619 173.436H841.03V193.786H828.661C816.714 193.786 809.429 188.914 807.438 180.074C806.938 178.787 806.569 177.452 806.337 176.091C799.474 188.702 786.652 196.215 766.772 196.215C739.802 196.23 722.108 183.181 722.38 161.262ZM803.501 149.767V133.415C799.081 140.264 787.814 141.817 775.43 143.356C760.843 145.136 748.686 147.112 748.686 159.497C748.686 169.665 756.862 175.638 772.112 175.638C789.971 175.668 803.456 165.501 803.456 149.797L803.501 149.767Z"
        fill="#1B1B1B"
      />
      <path
        d="M877.745 173.014L875.317 193.801H854.32V28H880.4V91.8706C888.802 80.3757 902.724 73.0745 919.301 73.0745C950.254 73.0745 972.095 98.2815 972.095 134.531C972.095 171.445 950.208 197.09 919.045 197.09C900.507 197.105 885.031 187.828 877.745 173.014ZM945.382 134.547C945.382 111.331 932.349 95.2044 913.344 95.2044C894.112 95.2044 880.4 111.12 880.4 133.883C880.4 158.02 894.112 175.005 913.344 175.005C932.334 175.005 945.367 158.427 945.367 134.547H945.382Z"
        fill="#1B1B1B"
      />
      <path
        d="M1037.11 193.802V39.0728H1063.64V169.952H1142.56V193.832L1037.11 193.802Z"
        fill="#1B1B1B"
      />
      <path
        d="M1152.94 161.262C1153.16 133.852 1180.36 128.074 1203.12 125.45C1220.8 123.458 1233.85 122.795 1234.07 113.518C1233.85 102.023 1225.02 94.5101 1209.94 94.5101C1194.46 94.5101 1183.42 102.897 1181.87 115.071H1155.59C1158.02 90.3012 1180.13 73.0586 1210.42 73.0586C1242.02 73.0586 1259.49 90.301 1259.49 115.297V168.353C1259.49 171.671 1261.25 173.436 1265.24 173.436H1271.65V193.786H1259.26C1247.33 193.786 1240.03 188.914 1238.04 180.074C1237.55 178.785 1237.18 177.451 1236.94 176.091C1230.09 188.702 1217.33 196.215 1197.37 196.215C1170.37 196.23 1152.73 183.181 1152.94 161.262ZM1234.07 149.767V133.415C1229.65 140.264 1218.37 141.817 1205.99 143.356C1191.4 145.136 1179.24 147.112 1179.24 159.497C1179.24 169.665 1187.43 175.638 1202.68 175.638C1220.59 175.668 1234.07 165.501 1234.07 149.797V149.767Z"
        fill="#1B1B1B"
      />
      <path
        d="M1284.47 193.801V76.6343H1305.47L1307.68 93.877C1314.97 81.7183 1329.78 73.0891 1347.25 73.0891C1372.23 73.0891 1388.58 89.8942 1388.58 116.203V193.801H1362.5V117.515C1362.5 104.467 1353.87 95.8532 1339.28 95.8532C1322.27 95.8532 1310.77 107.559 1310.62 122.81V193.711L1284.47 193.801Z"
        fill="#1B1B1B"
      />
      <path
        d="M1402.95 134.547C1402.95 98.3423 1425.28 73.0892 1456.44 73.0892C1473.03 73.0892 1486.73 79.9381 1494.9 90.9956V28H1521V193.801H1499.99L1497.56 173.014C1490.94 187.828 1476.34 197.151 1457.33 197.151C1425.72 197.105 1402.95 171.46 1402.95 134.547ZM1494.97 134.321C1494.97 111.12 1481.71 94.9783 1462.7 94.9783C1443.24 94.9783 1429.76 111.331 1429.76 134.773C1429.76 158.215 1443.03 175.005 1462.26 175.005C1481.49 175.005 1494.9 158.246 1494.9 134.321H1494.97Z"
        fill="#1B1B1B"
      />
      <path
        d="M1017.21 169.887C1017.21 168.3 1016.37 166.831 1014.99 166.037L1000.5 157.671C999.13 156.877 997.435 156.88 996.063 157.679L981.465 166.178C980.108 166.968 979.271 168.418 979.265 169.989L979.204 186.881C979.199 188.469 980.043 189.938 981.418 190.732L995.908 199.098C997.282 199.892 998.977 199.889 1000.35 199.091L1014.95 190.593C1016.31 189.803 1017.14 188.353 1017.15 186.781L1017.21 169.887Z"
        fill="#1B1B1B"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M605.944 97.9195C609.691 100.083 611.993 104.086 611.978 108.413L611.795 161.621C611.781 165.903 609.498 169.856 605.798 172.01L559.814 198.772C556.074 200.948 551.456 200.956 547.71 198.793L502.054 172.431C498.306 170.267 496.004 166.263 496.02 161.935L496.211 108.733C496.226 104.452 498.508 100.5 502.207 98.3465L548.182 71.5793C551.922 69.4022 556.54 69.3939 560.288 71.5576L605.944 97.9195ZM571.847 104.269C582.794 110.589 589.52 122.411 589.477 135.052C589.433 147.715 582.576 159.593 571.633 165.962C560.708 172.32 547.108 172.405 536.161 166.084C525.212 159.762 518.486 147.938 518.531 135.295C518.577 122.636 525.431 110.763 536.37 104.394C547.296 98.0333 560.898 97.9467 571.847 104.269Z"
        fill="#1B1B1B"
      />
      <defs>
        <clipPath id="clip0_1971_28294">
          <rect width="271.977" height="213" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Logo;
