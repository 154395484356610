import { useEffect, useState } from 'react';
import Navbar from '../common/NavBar';
import Button from '../common/Button';
/** SIWE method 
import { ethers } from 'ethers';
import PermissionScope from './PermissionScope';
import SIWEIcon from 'src/assets/siwe.png';
*/
/** reddit
import RedditIcon from 'src/assets/reddit-icon.png';
const COLLABLAND_SUBREDDIT_ID = process.env.REACT_APP_COLLABLAND_SUBREDDIT_ID;
const COLLABLAND_SUBREDDIT_NAME =
  process.env.REACT_APP_COLLABLAND_SUBREDDIT_NAME;
  */
import DiscordIcon from 'src/assets/discord-icon.svg';
import TelegramIcon from 'src/assets/telegram-logo.png';
import SadBot from 'src/assets/sad-robot-400.png';
import WavingBot from 'src/assets/waving-bot-200.png';
import PermissionScopes from './PermissionScope';

export interface AppSlug {
  response_type: string;
  client_id: string;
  redirect_uri: string;
  scope: string;
  state: string;
  platform: string;
  appName: string;
  appUrl: string;
  appIcon?: string;
}

export const SERVER =
  process.env.REACT_APP_SERVER_URL ?? 'https://api.collab.land';
export const LOGIN_VIEW_PLATFORMS = 'platforms';
export const LOGIN_VIEW_SCOPES = 'scopes';

const LOGIN_ACTION_AUTH = 'authorize';

const MainPageContent = (): JSX.Element => {
  const [redirect, setRedirect] = useState<string>();
  const [state, setState] = useState<string>('state');
  const [urlSlug, setUrlSlug] = useState<string>();
  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [slug, setSlug] = useState<AppSlug>();
  const [aeToken, setAeToken] = useState<string>();
  const [loginView, setLoginView] = useState<string>(LOGIN_VIEW_PLATFORMS);
  const [hostName, setHost] = useState<string>();
  /** SIWE Method 
   const [hostName, setHost] = useState<string>();
  const [challengeRes, setChallengeRes] = useState<{
    state: string;
    requestId: string;
    message: string;
  }>();
  const [signature, setSig] = useState<string>();
  const [walletAddr, setWalletAddr] = useState<string>();
*/
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const urlParams = new URLSearchParams(window.location.search);
      const redirect_uri = urlParams.get('redirect_uri');
      const state = urlParams.get('state');
      const urlSlug = urlParams.get('slug');
      const action = urlParams.get('action');
      const urlAeToken = urlParams.get('aeToken');
      setAeToken(urlAeToken);
      if (urlSlug) {
        setUrlSlug(urlSlug);
        loginState({ slug: urlSlug, action: action });
      }
      if (!redirect_uri) {
        console.warn('invalid redirect uri.');
        setErrorMsg('Please provide a valid redirect URI.');
        setHasError(true);
        return;
      }
      const url = new URL(redirect_uri);
      const host = url.hostname;
      setHost(host);
      if (
        ['localhost', '127.0.0.1', '[::1]'].includes(host) ||
        host.endsWith('collab.land')
        // host.endsWith('.charmverse.co') ||
        // host.endsWith('.charmverse.io')
      ) {
        setRedirect(redirect_uri);
        // setIsPartner(true);
      } else {
        setHasError(true);
        setErrorMsg(`Invalid redirect_uri: ${redirect_uri}`);
      }
    } else {
      setErrorMsg('Something went wrong D: Please try again later.');
      setHasError(true);
    }
  }, []);

  const loginState = async (req: { slug: string; action: string }) => {
    try {
      const url = `/states/${encodeURIComponent(req.slug)}`;
      const res = await fetch(`${SERVER}${url}`, {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      });
      if (res.status === 404) {
        setErrorMsg(`Session expired: ${req.slug}`);
        setHasError(true);
        return;
      }
      const data = await res.json();
      setSlug(data);
      if (req.action !== LOGIN_ACTION_AUTH) {
        setLoginView(LOGIN_VIEW_PLATFORMS);
      } else {
        setLoginView(LOGIN_VIEW_SCOPES);
      }
      return data;
    } catch (e) {
      setErrorMsg(`Invalid state: ${req.slug}.`);
      setHasError(true);
    }
  };

  /** SIWE method 
  const collablandChallenge = async (addr: string) => {
    try {
      const url = '/ethereum-login/challenges';
      const res = await fetch(`${SERVER}${url}`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify({
          state: state,
          uri: redirect,
          accountId: `evm:1:${addr}`,
        }),
      });
      const data = await res.json();
      if (data.error) {
        alert(`Error: ${data.error.message}`);
        return;
      }
      return data;
    } catch (e) {
      alert(`Error: ${e.message}`);
    }
  };

  const getCollablandToken = async (
    requestId: string,
    signature: string,
    scopeString: string
  ) => {
    try {
      const url = '/ethereum-login/id-tokens';
      const res = await fetch(`${SERVER}${url}`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify({
          requestId: requestId,
          signature: signature,
          scopes: scopeString,
        }),
      });
      const data = await res.json();
      if (data.error) {
        alert(`Error: ${data.error.message}`);
        return;
      }
      return data;
    } catch (e) {
      alert(`Error: ${e.message}`);
    }
  };
*/
  // logins
  const discordLogin = () => {
    window.location.href =
      `${SERVER}/discord/authorize?` +
      new URLSearchParams({
        state: state,
        redirect_uri: redirect,
        provider: 'discord',
      });
  };
  const telegramLogin = () => {
    window.location.href =
      `${SERVER}/telegram/authorize?` +
      new URLSearchParams({
        state: state,
        redirect_uri: redirect,
        provider: 'telegram',
      });
  };
  // const redditLogin = () => {
  //   window.location.href =
  //     `${SERVER}/reddit/authorize?` +
  //     new URLSearchParams({
  //       flow: 'admin',
  //       state: COLLABLAND_SUBREDDIT_ID,
  //       subreddit: COLLABLAND_SUBREDDIT_NAME,
  //       redirect_uri: redirect,
  //       provider: 'reddit',
  //     });
  // };

  /** SIWE method
  const signInWithEthereum = async () => {
    // make sure we enable wallets like MetaMask
    await window.ethereum.enable();
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    try {
      const addr = await signer.getAddress();
      setWalletAddr(addr);
      const res = await collablandChallenge(addr);
      setChallengeRes(res);
      const sig = await signer.signMessage(res.message);
      setSig(sig);
      // ask for permission scopes
      setLoginView(LOGIN_VIEW_SCOPES);
    } catch (e) {
      alert(e.message);
    }
  };

  const getCollabLandToken = async (scopeString: string) => {
    const token = await getCollablandToken(
      challengeRes.requestId,
      signature,
      scopeString
    );
    if (token.idToken) {
      if (
        redirect.startsWith('https://member.collab.land') ||
        redirect.startsWith('http://localhost')
      ) {
        window.location.href =
          `${redirect}?` +
          new URLSearchParams({
            walletAddress: walletAddr,
            aeToken: token.idToken,
            state: state,
            provider: 'ethereum',
          });
      } else {
        window.location.href =
          `${redirect}?` +
          new URLSearchParams({
            aeToken: token.idToken,
            state: state,
            provider: 'ethereum',
          });
      }
    } else {
      alert('Error: empty token id error. Please try again later.');
    }
  };
*/
  const LoginView = ({ loginView }: { loginView: string }) => {
    switch (loginView) {
      case LOGIN_VIEW_SCOPES:
        return (
          <PermissionScopes
            urlSlug={urlSlug}
            aeToken={aeToken}
            slug={slug}
            hostName={hostName}
          />
        );
      case LOGIN_VIEW_PLATFORMS:
      default:
        return (
          <>
            <div
              className="font-bold lg:text-3xl text-2xl"
              style={{ color: '#FFF' }}
            >
              Authenticate your account via
            </div>
            <div className="my-3">
              <img
                src={WavingBot}
                alt="logo"
                style={{
                  width: '205px',
                  height: '250px',
                }}
              />
            </div>
            <div className="grid grid-cols w-full mx-auto">
              <div className="grid grid-cols mx-auto gap-y-2 text-lg">
                {redirect && (
                  <>
                    <Button handleClick={discordLogin}>
                      <img
                        className="h-6 w-6 rounded-full mr-2"
                        alt="dis"
                        src={DiscordIcon}
                      />
                      <span>Discord</span>
                    </Button>
                    <Button handleClick={telegramLogin}>
                      <img
                        className="h-6 w-6 rounded-full mr-2"
                        alt="tel"
                        src={TelegramIcon}
                      />
                      <span>Telegram</span>
                    </Button>
                  </>
                )}
                {/* {redirect &&
                redditLoginAllowList(redirect) ? (
                  <Button handleClick={redditLogin}>
                    <img
                      className="h-6 w-6 rounded-full mr-2"
                      alt="tel"
                      src={RedditIcon}
                    />
                    <span>Reddit</span>
                  </Button>
                ) : null} */}
                {/* {(redirect &&
                  siweLoginAllowList(redirect)) ? (
                  <Button handleClick={() => signInWithEthereum()}>
                    <img
                      className="h-6 w-4 rounded-full ml-1 mr-3"
                      alt="tel"
                      src={SIWEIcon}
                    />
                    <span>Ethereum (SIWE)</span>
                  </Button>
                ) : null} */}
              </div>
            </div>
          </>
        );
    }
  };

  return (
    <div className="block h-screen evm-main-page new-index-bg">
      <Navbar />
      <div
        className="w-full mx-auto my-auto flex flex-col items-center"
        style={{ maxWidth: '1300px' }}
      >
        <div className="flex flex-col justify-start items-center text-center xl:mt-20 sm:mt-24 mt-20 mb-10 lg:text-2xl sm:text-xl text-lg text-cl-yellow-200">
          {!hasError ? (
            <LoginView loginView={loginView} />
          ) : (
            <>
              <div className="grid grid-cols w-full mx-auto mt-5">
                <div
                  className="font-bold lg:text-3xl text-2xl"
                  style={{ color: '#FFF' }}
                >
                  {errorMsg}
                </div>
              </div>
              <div className="my-3">
                <img
                  src={SadBot}
                  alt="logo"
                  style={{
                    width: '205px',
                    height: '250px',
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default MainPageContent;
