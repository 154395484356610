import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Login from './components/MainLogin';

const App: React.FC = () => {
  return (
    <div className="body content collabland">
      <Switch>
        <Route exact path="/" component={Login} />
      </Switch>
    </div>
  );
};

export default App;
