import { useState } from 'react';
import Button from '../common/Button';
import { AppSlug, SERVER } from './MainLogin';
import GppGoodIcon from '@mui/icons-material/GppGood';
import LinkIcon from '@mui/icons-material/Link';
import LockIcon from '@mui/icons-material/Lock';
import { scopes, defaultScope } from './scopes';
import Scope from './Scope';
import defaultAppImg from 'src/assets/gear_1.png';

const PermissionScopes = ({
  hostName,
  slug,
  urlSlug,
  aeToken,
}: {
  hostName: string;
  slug: AppSlug;
  urlSlug: string;
  aeToken: string;
}): JSX.Element => {
  const [rememberDecision, setDecision] = useState<boolean>(false);
  return (
    <>
      <div
        className="bg-cl-gray-300 border-white rounded flex flex-col items-start w-full authBoxShadow"
        style={{ minWidth: '200px' }}
      >
        <div className="p-8">
          <div
            className="flex flex-row items-center w-full font-semibold text-lg pb-5 border-b-2 border-cl-gray-200"
            style={{ color: '#FEBA35' }}
          >
            <span>App</span>
            <a
              className="introLink flex flex-row items-center"
              href={slug.appUrl}
              target="_blank"
              rel="noreferrer noopener"
            >
              <span className="introLink">
                <div className="ml-2 text-white text-lg">{slug.appName}</div>
              </span>

              <div className="px-2">
                <img
                  className="w-10 h-10 rounded-full"
                  src={slug.appIcon ?? defaultAppImg}
                  alt="app"
                />
              </div>
            </a>
            {/* <img src={slug.appIcon} /> */}
            <div> is asking for the following permissions:</div>
          </div>
          <div className="w-full text-white text-base py-5 border-b-2 border-cl-gray-200">
            <Scope scope={defaultScope} />
            {slug.scope.split(' ').map((s) => {
              const explanations = scopes[s];
              if (explanations) {
                return explanations.map((e) => <Scope scope={e} />);
              }
            })}
            {/* <label className="checkBoxContainer flex flex-row items-center">
              <span>Access your identity (name and id)</span>
              <input type="checkbox" checked disabled value={'identities'} />
              <span className="checkmarkScope"></span>
            </label>
            <label className="checkBoxContainer flex flex-row items-center">
              <span>Read your shared information from Collab.Land</span>
              <input type="checkbox" checked disabled />
              <span className="checkmarkScope"></span>
            </label> */}
          </div>
          <div className="grid grid-cols-1 gap-y-1 py-5 text-cl-gray-100 text-sm text-left border-b-2 border-cl-gray-200">
            <div className="flex flex-row items-center">
              <LinkIcon fontSize="small" className="mr-1" />
              Once you authorize this app, you will be redirected to: https://
              {hostName}
            </div>
            <div className="flex flex-row items-center">
              <LockIcon fontSize="small" className="mr-1" />
              The developer of Collab.Land's privacy policy and terms of service
              apply to this application.
            </div>
            <div className="flex flex-row items-center">
              <GppGoodIcon fontSize="small" className="mr-1" />
              Collab.Land will not share data outside of above scopes with third
              party apps.
            </div>
          </div>
          <div className="w-full text-white text-base pt-8">
            <label className="checkBoxContainer flex flex-row items-center">
              <span>Remember my decision for this app</span>
              <input
                type="checkbox"
                value={'identities'}
                onChange={() => setDecision(!rememberDecision)}
              />
              <span className="checkmark"></span>
            </label>
          </div>
        </div>
        <div className="flex flex-row w-full justify-around bg-cl-gray-200 rounded-b py-4 bg-opacity-50">
          <button
            className="font-bold text-cl-gray-100 sm:text-base xs:text-base text-sm lg:px-10 sm:px-5 xs:px-3 px-2 xs:py-2 py-1 hover:text-white duration-100 "
            onClick={() => {
              window.location.href =
                `${SERVER}/oauth2/callback/${urlSlug}?` +
                new URLSearchParams({
                  aeToken: aeToken,
                  userAuthorization: 'denied',
                });
            }}
          >
            Deny
          </button>

          <Button
            // filled={true}
            // bold={true}
            handleClick={async () => {
              const scopes = slug.scope.split(' ');
              await fetch(`${SERVER}/user-authorizations/${urlSlug}`, {
                method: 'POST',
                headers: new Headers({
                  Authorization: `AE ${aeToken}`,
                  'Content-Type': 'application/json',
                }),
                body: JSON.stringify({
                  scopes,
                  rememberDecisions: rememberDecision,
                }),
              });

              window.location.href =
                `${SERVER}/oauth2/callback/${urlSlug}?` +
                new URLSearchParams({
                  aeToken: aeToken,
                  userAuthorization: 'granted',
                });
            }}
          >
            Authorize
          </Button>
        </div>
      </div>
    </>
  );
};
export default PermissionScopes;
