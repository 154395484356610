const Scope = ({ scope }: { scope: string }): JSX.Element => {
  return (
    <label className="checkBoxContainer flex flex-row items-center py-1">
      <span>{scope}</span>
      <input type="checkbox" checked disabled value={'identities'} />
      <span className="checkmarkScope"></span>
    </label>
  );
};

export default Scope;
