import React from 'react';
import Logo from '../Icons/logo';

const Navbar = () => {
  return (
    <div className="new-navbar-container xl:py-4">
      <nav
        // position="fixed" 
        className="new-navbar xl:rounded-full xl:mx-10 3xl:py-2 py-1 xl:px-3"
      >
        <div
          className="flex flex-row items-center sm:m-3 m-2"
          style={{ minHeight: '47px' }}
        >
          <Logo />
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
