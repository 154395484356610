import React from 'react';

const Button = ({
  handleClick,
  children,
  disabled = false,
}: {
  handleClick: any;
  children: React.ReactNode;
  disabled?: boolean;
}) => {
  return (
    <button
      className="new-button flex flex-row items-center lg:py-3 py-2 lg:px-12 px-8"
      type="button"
      onClick={handleClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
