export const scopes: {
  'gm-pass:read': string[];
  'verifiable-credential:read': string[];
  'verifiable-credential:write': string[];
  'user:verifiable-credential:read': string[];
  'user:verifiable-credential:write': string[];
  'user:wallet:write': string[];
  'user:wallet:read': string[];
  'user:community:read': string[];
  'user:webauthn-credential:read': string[];
  'user:webauthn-credential:write': string[];
} = {
  'gm-pass:read': ['Read gmPASS address and DID information'],
  'verifiable-credential:read': [
    'Read certain type public verifiable credentials issued to you',
  ],
  'verifiable-credential:write': ['Issue you public verifiable credentials'],
  'user:verifiable-credential:read': [
    'Read all public verifiable credentials issued to you',
  ],
  'user:verifiable-credential:write': [
    'Issue you Discord role verifiable credentials',
  ],
  'user:wallet:write': ['Add/remove wallets for you'],
  'user:wallet:read': [
    'Read all of your wallet address you connected with collab.land and balances of them',
  ],
  'user:community:read': [
    'See communities you are in and administrating and roles',
  ],
  'user:webauthn-credential:read': ['Read your webauthn credentials'],
  'user:webauthn-credential:write': ['Add/remove your webauthn credentials'],
};

export const defaultScope =
  'Read basic user information including user name, user id, and profile picture';
